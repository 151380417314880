#event {
  background-image: url("/src/images/img_mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: relative;
  top: -8px;
}
#event h3{
    font-size: 2.75rem;
}
#event h2 {
    font-size: 3.5rem;
}
#event .colorDefault {
    width: 300px;
}
#event .colorDefault h3{
    font-size: 2rem !important;
}
#horloge{
    width: 60px;
    height: 60px;
}
.borderColorDefault {
    border-color: #2F2254 !important; 
}
.textColorDefault {
    color: #2F2254 !important; 
}
#logoSwitch {
    width: 150px;
}
@media (min-width: 510px) {
  #event {
    background-image: url("/src/images/img_desktop.png");
    position: initial;
  }
}
@media (min-width: 576px) {
  #event {
    background-image: url("/src/images/img_desktop.png");
  }
}
@media (min-width: 768px) {
    .borderColorDefault {
        border: none !important;
        border-right: solid 2px #2F2254 !important;
    }
    #horloge{
        width: 40px;
        height: 40px;
    }
    #logoSwitch {
        position: absolute;
        right: 3%;
        bottom: 50px;
        width: 180px;
    }
    #event h3{
        font-size: 4rem;
    }
    #event h2 {
        font-size: 5rem;
    }
}
