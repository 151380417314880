.FirstPic {
    background-image: url(/public/images/FirstPic.jpg);
    background-size: cover;
}
.SecondPic {
    background-image: url(/public/images/SecondPic.jpg);
    background-size: cover;

}
section.colorDefault {
    margin: 10%;
    opacity: 85%;
}
.FirstPic img {
    height: 200px;
    width: 200px;
}
@media (min-width : 640px ) {
    .FirstPic img {
        display: initial;
        height: 277px;
        width: 277px;
    }
}