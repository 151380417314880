.colorDefault {
    background-color: #2F2254;
}
header {
    height: 83px;
}
.logo {
    width: 70px;
    position: absolute;
   left:  calc(50% - 35px); 
   top: -20px;
   
}
 header ul {
    list-style: none;
 }
 header {
    font-size: 16px;
 }
h1 {
    font-size: 16px;
    left:  calc(50% - 48px); 
    bottom: 2px;
    position: absolute;
}
ul {
    position: absolute;
    width: 100vw;
    bottom: 0;
}
a {
    text-decoration: none !important;
  }
@media (min-width : 640px ) {
     .logo {
        width: 128px;
        height: 128px;
        position: relative;
        left: 30px;
        top: 0;
       
     }
     header {
        padding: 0 0 0 10%;
        font-size: 24px;
    }
    header h1 {
        font-size: 24px;
        position: initial !important;
        margin-left: 40px;
     }
     ul {
        width: 250px;
        padding: 0 !important;
        bottom: initial !important;
        right: 10%;
     }
}