#nom, #email {
    height: 40px;
}
.bg-custom {
    background-color:#eeeeee;
}
#Submit {
    width: min-content;
    
    transition: background-color 0.3s ease, color 0.3s ease; 
}
#Submit:hover {
    background-color: #2F2254;
    color: white;
    
}

@media (min-width: 768px) {
    #Contact {
        display: flex;
    }
    
}