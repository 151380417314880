#maillot {
    width: 225px;
}
@media (min-width : 768px) {
    #maillot {
        width: 100%;
    }
    .containerWidth {
        width: 50%;
    }
    .containerWidth P {
        margin: 0 !important;
    }
    .containerWidth h3 {
        margin: 0 0 50px 0 !important;
    }
}