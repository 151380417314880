footer ul {
  list-style: none;
  bottom: 65px;
}
footer .logo {
  width: 96px;
  height: 96px;
  left: calc(50% - 48px);
  top: 10px;
}
p {
  text-decoration: none;
}
footer a {
  text-decoration: none;
}
footer {
  height: 200px;
}
.link {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 97.75px);
}
footer h3 {
    font-size: 16px;
    left: calc(50% - 48px);
    bottom: 65px;
    position: absolute;
}
@media (min-width: 640px) {
    footer {
        height: 154px;
    }
    footer .logo {
        width: 128px;
        height: 128px;
        position: relative;
        left: 30px;
        top: 0;
    }
    footer {
        padding: 0 0 0 10%;
        font-size: 24px;
    }
    footer h3 {
        font-size: 24px;
        position: initial !important;
        margin-left: 40px;
    }
    .link {
        font-size: 16px;        
  }
}
@media (min-width: 1050px) {
  
    .link {  
        bottom: initial !important;    
  }
}

